import React from "react";
import { PageProps } from "gatsby";
import pagesMeta from "data/pagesMeta";
import * as styles from "./guideCategory.module.scss";

import { Hero, SideNav } from "components/guides";
import { Related } from "components/design-system";
import SEO from "components/SEO";

const GuidesCategory: React.FC<PageProps> = ({ pageContext }) => {
  const { guides, popularGuides, category } = pageContext as any;
  const categoryPath = `/guides/${category.attributes.slug}`;
  const relatedItems = [...popularGuides].slice(0, 2).map(
    ({
      attributes: {
        title,
        featuredImage: {
          data: {
            attributes: { localFile: image, url, ext },
          },
        },
        slug,
        description,
      },
    }) =>
      Object.assign(
        {
          title,
          image,
          link: `${categoryPath}/${slug}`,
          excerpt: description,
        },
        ext === ".gif" ? { featuredImage: url } : { image }
      )
  );

  return (
    <>
      <SEO {...pagesMeta.guides} />
      <div className={styles.Guides}>
        <SideNav
          type="Category"
          title={category.attributes.name}
          categories={guides}
          pathPath={`/guides/${category.attributes.slug}`}
        />
        <div className={styles.Guides_main}>
          <Hero title={category.attributes.name} />
          <div className={styles.Guides_related}>
            <Related
              align="left"
              overflow={false}
              title="Popular topics on our business guide"
              items={relatedItems}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GuidesCategory;
